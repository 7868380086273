<template>
  <div>
    <div
      v-if="isEditable"
      class="mb-1 d-flex gap-1 align-items-center"
    >
        <b-form-file
                v-model="files"
                :placeholder="t('filesUpload.placeholder')"
                :drop-placeholder="t('filesUpload.dropPlaceholder')"
                :browse-text="t('filesUpload.browseText')"
                :multiple="true"
                accept=".jpg, .png, .jpeg, .gif, .pdf, .doc, .docx,
      .xlsx, .xls, .txt, .mp4, .ogx, .oga, .ogv, .ogg, .webm, .mov"
                @input="handleUpload"
        >
            <template #file-name>
                Прикреплено файлов: {{ files.length }}
            </template>
        </b-form-file>
        <feather-icon
            id="validation"
            icon="InfoIcon"
            :title="t('filesUpload.validationTitle')"
            size="1.25x"
        />
        <b-popover
          target="validation"
          triggers="hover"
        >
            <template #title>
                {{ t("filesUpload.validationTitle") }}
            </template>
            <div>
                <p>{{ t("filesUpload.acceptTypes") }}</p>
                <p>{{ t("filesUpload.maxSize") }}</p>
                <p>{{ t("filesUpload.maxQuantity") }}</p>
            </div>
        </b-popover>
    </div>
    <div
      v-if="isEditable && files.length"
      class="mt-1 gap-2 d-flex justify-content-end"
      style="padding-right: 24px"
    >
      <b-button
        variant="primary"
        @click="handleSubmit"
      >
        Загрузить
      </b-button>
      <b-button @click="handleClear">
        Очистить
      </b-button>
    </div>
    <div
      class="mt-1"
    >
        <div class="d-flex flex-row gap-2 align-items-center date-select">
            <EntityTableSearch
                    :query.sync="pagination.q"
                    class="file-type-search"
            />
            <BFormSelect
                    v-model="selectedFileType"
                    class="file-type-select"
                    :options="[
                    {text: typeFilterPlaceholder, value: null, disabled: false},
                    ...fileTypes]"
                    @change="updateType"
            />
            <CalendarRange
                    opens="right"
                    @update="updateRange"
            />
            <div>
                <feather-icon
                        :icon="'XCircleIcon'"
                        :size="'24'"
                        @click="clearFilters"
                />
            </div>
        </div>
      <div
        v-if="propFiles.data.length"
        class="overflow-auto mb-2"
      >
          <b-table
              :fields="fields"
              :items="propFiles.data"
              :sort-by.sync="pagination.order_by"
              :sort-desc.sync="pagination.sortDesc"
              class="mt-1"
          >
              <template #cell(original_name)="data">
                  <div class="d-flex flex-row flex-nowrap align-items-center gap-2">
                      <div
                        v-if="isImage(data.item.extension) && data.item.status.id === 1"
                        :style="{ width: 'min-content', height: 'fit-content' }"
                      >
                        <b-img
                          :src="data.item.url"
                          :alt="data.item.name"
                          :style="{ width: '45px' }"
                          class="cursor-pointer"
                          rounded
                          @click="toggleModalOpen(data.index + 1)"
                        />
                      </div>
                      <div
                        v-if="isImage(data.item.extension) && data.item.status.id !== 1"
                        :style="{ width: '45px', height: '45px', backgroundColor: '#FEF1E1' }"
                        class="d-flex align-items-center
                        justify-content-center rounded flex-shrink-0"
                      >
                        <ImageIcon />
                      </div>
                      <div
                        v-if="data.item.extension === 'pdf'"
                        :style="{ width: '45px', height: '45px', backgroundColor: '#FCE4E4' }"
                        class="d-flex align-items-center
                        justify-content-center rounded flex-shrink-0"
                      >
                        <Pdf />
                      </div>
                      <div
                        v-if="data.item.extension === 'xlsx' || data.item.extension === 'xls'"
                        :style="{ width: '45px', height: '45px', backgroundColor: '#DCF6E8' }"
                        class="d-flex align-items-center
                        justify-content-center rounded flex-shrink-0"
                      >
                        <Xlsx />
                      </div>
                      <div
                        v-if="data.item.extension === 'docx'
                              || data.item.extension === 'doc'
                              || data.item.extension === 'txt'"
                        :style="{ width: '45px', height: '45px', backgroundColor: '#ddecff' }"
                        class="d-flex align-items-center
                        justify-content-center rounded flex-shrink-0"
                      >
                        <Docx />
                      </div>
                      <div
                        v-if="data.item.extension === 'mp4'
                              || data.item.extension === 'webm'
                              || data.item.extension === 'mov'"
                        :style="{ width: '45px', height: '45px', backgroundColor: '#E8E7FD' }"
                        class="d-flex align-items-center
                        justify-content-center rounded flex-shrink-0"
                      >
                        <Movie />
                      </div>
                      <div class="flex flex-column">
                          <p
                            class="mb-0 font-weight-bold text-overflow"
                            :title="data.item.original_name"
                            @click="data.item.status.id === 1 && isImage(data.item.extension)
                                    ? toggleModalOpen(data.index + 1)
                                    : data.item.status.id === 1 && !isImage(data.item.extension)
                                    ? handleDownload(data.item.id, data.item.original_name)
                                    : undefined"
                          >
                              {{ data.item.original_name }}
                          </p>
                          <p
                            v-if="'facility' in data.item"
                            class="mb-0 text-decoration-underline cursor-pointer text-overflow"
                            :title="data.item.facility.name"
                            @click="handleShowFacility(data.item.facility.id)"
                          >
                            {{ data.item.facility.name }}
                          </p>
                          <p
                            v-if="'request' in data.item"
                            class="mb-0 text-decoration-underline cursor-pointer text-overflow"
                            :title="data.item.request.name"
                            @click="handleShowRequest(data.item.request.id)"
                          >
                            {{ data.item.request.name }}
                          </p>
                      </div>
                  </div>
              </template>
              <template #cell(size)="data">
                  <p
                    v-if="data.item.status.id === 1"
                    class="mb-0 text-nowrap text-align"
                  >
                      {{ (data.item.size / 1024 / 1024).toFixed(2) >= 1
                      ? `${ (data.item.size / 1024 / 1024).toFixed(2) } МБ`
                      : `${ (data.item.size / 1024).toFixed(2) } КБ` }}
                  </p>
                  <div
                    v-else
                    class="d-flex align-items-center justify-content-center"
                  >
                      <BSpinner
                        variant="primary"
                        style="width: 20px; height: 20px;"
                      />
                  </div>
              </template>
              <template #cell(actions)="data">
                  <div class="d-flex align-items-center justify-content-center gap-2">
                      <div
                          class="hover-icon"
                          :class="{ disabled: data.item.status.id !== 1 }"
                          @click="data.item.status.id === 1
                                  ? handleDownload(data.item.id, data.item.original_name)
                                  : undefined"
                      >
                          <feather-icon
                              icon="DownloadIcon"
                              size="1.5x"
                          />
                      </div>
                      <div
                              v-if="isEditable"
                              class="hover-icon"
                      >
                          <feather-icon
                                  icon="Trash2Icon"
                                  size="1.5x"
                                  class="cursor-pointer"
                                  @click="handleSetDeleteFile(data.item.id)"
                          />
                      </div>
                  </div>
              </template>
          </b-table>
      </div>
      <h5
        v-else
        class="text-center mt-2"
      >
        Файлы отсутствуют
      </h5>
      <div class="mt-2 d-flex justify-content-end">
          <b-pagination
              v-if="propFiles.total > 5"
              v-model="pagination.page"
              :total-rows="propFiles.total"
              :per-page="pagination.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
              <template #prev-text>
                  <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                  />
              </template>
              <template #next-text>
                  <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                  />
              </template>
          </b-pagination>
      </div>
    </div>
    <b-modal
      ref="modalRef"
      v-model="isModalOpen"
      size="lg"
      :title="t('filesUpload.modal.files')"
    >
      <div class="fileModalImages">
        <b-carousel
          v-model="slide"
          controls
          label-next="''"
          label-prev="''"
          :interval="400000"
          style="width: 100%"
          indicators
        >
          <b-carousel-slide
            v-for="item in propFiles.data.filter((i) => isImage(i.extension))"
            :key="`slide-${item.id}`"
          >
            <template #img>
              <div class="d-flex flex-column align-items-center">
                <img
                  :src="item.url"
                  style="width: 60%; max-height: 50vh"
                >
              </div>
            </template>
          </b-carousel-slide>
        </b-carousel>
      </div>
      <template #modal-footer="{}">
          <BButton @click="$refs['modalRef'].hide()">
              {{ t("filesUpload.modal.close") }}
          </BButton>
      </template>
    </b-modal>
    <b-modal
      v-model="isDeleteModalOpen"
      centered
      :title="t('filesUpload.modal.deleteFile')"
      size="sm"
      :ok-title="t('filesUpload.modal.confirmDeleteButton')"
      :cancel-title="t('filesUpload.modal.declineDeleteButton')"
      @ok="handleDelete"
    >
        <h5>{{ t('filesUpload.modal.confirmDeleteText') }}</h5>
    </b-modal>
  </div>
</template>

<script>
import {
    BButton,
    BFormFile,
    BTable,
    BImg,
    BModal,
    BCarousel,
    BCarouselSlide,
    BPagination,
    BSpinner,
    BPopover,
    BFormSelect
} from 'bootstrap-vue';
import {computed, ref, watch} from "@vue/composition-api";
import fileTypeByExt from "@/utils/fileTypeByExt";
import { useRouter } from "@/hooks/useRouter";
import Pdf from "@/assets/images/icons/file-icons/Pdf.vue";
import Xlsx from "@/assets/images/icons/file-icons/Xlsx.vue";
import Docx from "@/assets/images/icons/file-icons/Docx.vue";
import Movie from "@/assets/images/icons/file-icons/Movie.vue";
import ImageIcon from "@/assets/images/icons/file-icons/ImageIcon.vue";
import { useI18n } from "@/hooks/useI18n";
import { useToast } from '@/hooks/useToast';
import {axiosIns} from "@/store/api";
import EntityTableSearch from "@/components/entityTables/EntityTableSearch.vue";
import CalendarRange from "@/components/fields/calendarRange/CalendarRange.vue";

export default {
  components: {
      CalendarRange,
      BFormSelect,
    BPagination,
    BButton,
    BFormFile,
    BTable,
    BImg,
    BModal,
    BCarousel,
    BCarouselSlide,
    Pdf,
    Xlsx,
    Docx,
    Movie,
    ImageIcon,
    BSpinner,
    BPopover,
    EntityTableSearch
  },
  props: {
    isEditable: {
        type: Boolean,
        default: false,
    },
    items: {
        type: Object,
        default: () => {},
    },
    payload: {
        type: Object,
        default: () => {},
    },
      fileTypes: {
        type: Array,
          default: () => [],
      }
  },
  setup(props, {emit}) {
    const files = ref([]);
    const propFiles = ref(props.items);
    const { getFileType } = fileTypeByExt();
    const router = useRouter();
    const pagination = ref(props.payload ?? {
        page: 1,
        perPage: 5
    });
    const { t } = useI18n();
    const { dangerToast } = useToast();
    const fileType = (ext) => getFileType(ext);
    const isImage = (ext) => fileType(ext) === 'image';
    const typeFilterPlaceholder = computed(() => t('filesUpload.filters.type.placeholder'));
    const selectedFileType = ref(pagination.value.type || null);

    const fields = ref([
        {
            key: 'original_name',
            label: t('filesUpload.fields.file'),
            sortable: true,
        },
        {
            key: 'size',
            label: t('filesUpload.fields.size'),
            sortable: true,
            thStyle: {
                textAlign: 'center',
            }
        },
        {
            key: 'actions',
            label: t('filesUpload.fields.actions'),
            thStyle: {
                textAlign: 'center',
            }
        }
    ]);

    const isModalOpen = ref(false);
    const isDeleteModalOpen = ref(false);
    const deleteFileId = ref();
    const slide = ref(0);
    const toggleModalOpen = (id) => {
      slide.value = id - 1;
      isModalOpen.value = !isModalOpen.value
    }
    const handleDelete = () => {
        emit('delete', deleteFileId.value);
    }
    const handleSubmit = () => {
        emit('submit', files.value);
        files.value = [];
    }
    const handleClear = () => {
        files.value = []
    };
    const handleSetDeleteFile = (id) => {
        deleteFileId.value = id;
        isDeleteModalOpen.value = true;
    }

    const handleShowRequest = (id) => {
        router.redirect(`/requests/${id}`);
    }

    const handleShowFacility = (id) => {
        router.redirect(`/facility/${id}`);
    }

    const handleUpload = (v) => {
      let array = v;

      if (v.some((i) => (i.size / 1024 / 1024) > 15)) {
        array = array.filter((i) => (i.size / 1024 / 1024) <= 15);
        dangerToast('Прикрепление файлов', 'Превышен допустимый размер загружаемого файла. Прикреплены файлы с размером до 15 МБ')
      }
      if (v.length > 10) {
        array = array.slice(0, 10);
        dangerToast('Прикрепление файлов', 'Превышено допустимое количество прикрепляемых файлов. Прикреплены первые 10 файлов');
      }

      files.value = array;
    }

    const handleDownload = async (id, filename) => {
        await axiosIns.get(`/tools/download-file/${id}`, { headers: { Accept: '*/*' }, responseType: "blob" })
            .then((res) => {
                const csvURL = window.URL.createObjectURL(res.data);
                const tempLink = document.createElement('a');
                tempLink.href = csvURL;
                tempLink.setAttribute('download', filename);
                tempLink.click();

                tempLink.remove();
            })
    };
    const handleGet = () => {
        emit('get', pagination.value);
    };

    const updateType = (val) => {
        pagination.value.type = val;
        handleGet();
    };

      const updateRange = (payload) => {
          pagination.value.dateFrom = payload.dateFrom;
          pagination.value.dateTo = payload.dateTo;
          handleGet();
      };

      const clearFilters = () => {
          pagination.value.dateFrom = null;
          pagination.value.dateTo = null;
          pagination.value.type = null;
          pagination.value.q = null;
          selectedFileType.value = null;
          handleGet();
      };

    watch(() => props.items, () => {
        propFiles.value = props.items;

        if (!propFiles.value.data.length && pagination.value.q && pagination.value.page > 1) {
          pagination.value.page = 1;
        }
    });

    watch(pagination.value, () => {
        handleGet();
    });

    watch(propFiles.value, () => {
        if (propFiles.value.data.length === 1 && pagination.value.page > 1) {
            pagination.value.page -= 1;
        }
    });

    watch(propFiles.value, () => {
        pagination.value = {
            page: propFiles.value.current_page,
            perPage: propFiles.value.per_page,
            total: propFiles.value.total
        }
    });

    return {
        t,
        files,
        propFiles,
        isImage,
        fields,
        isModalOpen,
        isDeleteModalOpen,
        handleSetDeleteFile,
        deleteFileId,
        slide,
        toggleModalOpen,
        handleDelete,
        handleSubmit,
        handleClear,
        pagination,

        handleShowRequest,
        handleShowFacility,
        handleUpload,
        handleDownload,
        updateRange,
        updateType,
        clearFilters,
        handleGet,
        typeFilterPlaceholder,
        selectedFileType,
    }
  }
}
</script>

<style scoped lang="scss">
.text-overflow {
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 196px;
  //display: -webkit-box;
  //-webkit-line-clamp: 1;
  //line-clamp: 1;
  //-webkit-box-orient: vertical;
}

.text-align {
  text-align: center;

  div {
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
  }
}

a {
  color: unset !important;
}

.hover-icon {
  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  border-radius: 100%;
  transition: background-color .2s;

  &:not(.disabled):hover {
    background-color: #e8e8e8;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
.date-select {
  cursor: pointer;
  color: rgb(115, 103, 240);
  text-decoration: unset !important;
}
.file-type-select {
  width: unset !important;
}
.file-type-search {
  max-width: unset !important;
}
</style>
